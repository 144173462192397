
import {setPageHeader,} from "@/core/helpers/toolbar";
import { defineComponent, onMounted } from "vue";
import OrdersList from "@/modules/order/components/OrdersList.vue"

export default defineComponent({
	name: "orders",
	components: {
      
    	OrdersList,
	},
	setup() {
		onMounted(() => {
            setPageHeader({
                title: "message.ORDERS",
                actionButton : null,
                breadCrumbs: [
                    { name: "message.HOME", link: "/dashboard"},
                    { name: "message.ORDERS", link: "#"},
                    { name: "message.ORDERS"}
                ]
            })
		});
	},
});
